.heroSliderSection{
    .buttonsGroup{
        padding-top: 80px;
        width: 230px !important;
        a{
            margin-top: 14px;
            padding: 6px 20px;
        }
    }
    .heroSlider {
        .swiper-pagination-bullet {
            width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
            height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
            display: inline-block;
            border-radius:20px;
            background-color: white;
            opacity: 1;
            padding: 10px;
            border: 1px solid $base-color-1;
        }
        .swiper-pagination-bullet-active {
            padding:10px 20px ;
            background: $base-color-1 !important;
        }
    }
    a.btn{
        &:hover{
            background: white;
            color: black;
            border-color: black;
        }
    }
}

