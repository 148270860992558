#weight_loos{
    .afterBeforeSwiper{
        .swiper-pagination-bullet {
            width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px));
            height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px));
            display: inline-block;
            border-radius:20px;
            background-color: white;
            opacity: 1;
            padding: 10px;
            border: 1px solid $base-color-1;
        }
        .swiper-pagination-bullet-active {
            padding:10px 20px ;
            background: $base-color-1 !important;
        }
        .swiper-button-next,
        .swiper-button-prev{
            width: 40px;
            height: 50px;
        }
        .swiper-button-next:after, .swiper-button-prev:after{
            color: white !important;
        }
    }

}


@media (max-width: 992px) {
    .afterBeforeSwiper{
        .swiper-button-next,
        .swiper-button-prev{
            width: 30px !important;
            height: 35px !important;
        }
        .swiper-button-next:after, .swiper-button-prev:after{
            color: white !important;
            font-size: 14px !important;
        }
    }
}

